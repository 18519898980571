import { InfoComponent } from './../shared/info/info.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { HomeComponent } from '../shared/home/home.component';
import { NotfoundComponent } from '../shared/notfound/notfound.component';
import { ContactComponent } from '../shared/contact/contact.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    InfoComponent,
    ContactComponent,
    NotfoundComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    PagesRoutingModule,
    SharedModule
  ]
})
export class PagesModule { }
