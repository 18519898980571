import { TodayEventModule } from './unprotected/today-event/today-event.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './shared/notfound/notfound.component';

const routes: Routes = [
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: 'paspatur/:id',  loadChildren: () => import('./unprotected/paspatur/paspatur.module').then(m => m.PaspaturModule),
          pathMatch: 'full' },
    { path: 'today-event',  loadChildren: () => import('./unprotected/today-event/today-event.module').then(m => m.TodayEventModule),
          pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

