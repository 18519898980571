import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../shared/home/home.component';
import { ContactComponent } from '../shared/contact/contact.component';
import { NotfoundComponent } from '../shared/notfound/notfound.component';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  { path: 'pages', component: PagesComponent, children: [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'events',  loadChildren: () => import('./event/event.module').then(m => m.EventModule) },
    { path: 'contact', component: ContactComponent },
    { path: '404', component: NotfoundComponent },
    { path: '**', redirectTo: '/404' }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
