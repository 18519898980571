import { Component, OnInit } from '@angular/core';

import { defineCustomElements } from 'corporate-ui-dev/dist/';

defineCustomElements(['c-header', 'c-navigation']);


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links = [
      { text: 'Eventos', url: 'events' }
  ];

  dropdownData = [{
    id: 1,
    name: 'Profile'
  }, {
    id: 2,
    name: 'Settings'
  }];

  constructor() { }

  ngOnInit() {
  }

}
