import { QRCodeModule, QRCodeComponent } from 'angularx-qrcode';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'angular-dropdown-component';

import { HeaderComponent } from './header/header.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  exports: [HeaderComponent, CommonModule, DropdownModule, NgbModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    DropdownModule,
    RouterModule,
    CommonModule,
    NgbModule,
    HttpClientModule
  ]
})
export class SharedModule { }
